import { HTTP } from '@/libs/https.js';
import config from '@/libs/config.js';

class CampusOAModel extends HTTP {
  getProcessHandOverList (data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.GET_PROCESS_HAND_OVER_LIST,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  }
  getSchoolFormGroup (data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.GET_SCHOOL_FORM_GROUP,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  }
  saveSchoolFormGroup (data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.SAVE_SCHOOL_FORM_GROUP,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  }

  getConditionByList (data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.GET_CONDITION_BY_LIST,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  }

  getSchoolFormGroupList (data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.GET_SCHOOL_FORM_GROUP_LIST,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  }
  //
  //CONDITION_LIST
  conditionList (data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.GET_HANDOVER_FORM_LIST,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  }

  schoolFormGroupSave (data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.SCHOOL_FORM_GROUP_SAVE,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  }

  schoolFormGroupSort (data) {
    return new Promise((resolve, reject) => {
      this.axiosPostArray({
        url: config.API.SCHOOL_FORM_GROUP_SORT,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  }

  schoolFormGroupInfo (data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.SCHOOL_FORM_GROUP_INFO,
        data: data,
        header: 'application/json',
        success (data) {
          resolve({
            data,
            status: 0
          });
        },
        error (error) {
          resolve({
            error,
            status: - 1
          })
        }
      });
    });
  }

    schoolFormGroupDelete (data) {
        return new Promise((resolve, reject) => {
        this.axiosPostArray({
            url: config.API.SCHOOL_FORM_GROUP_DELETE,
            data: data,
            header: 'application/json',
            success (data) {
            resolve({
                data,
                status: 0
            });
            },
            error (error) {
            resolve({
                error,
                status: - 1
            })
            }
        });
        });
    }
    getHandoverFormList (data) {
        return new Promise((resolve, reject) => {
        this.axiosPostArray({
            url: config.API.GET_HANDOVER_FORM_LIST,
            data: data,
            header: 'application/json',
            success (data) {
            resolve({
                data,
                status: 0
            });
            },
            error (error) {
            resolve({
                error,
                status: - 1
            })
            }
        });
        });
    }
    // 保存
    schoolFormInfoSave (data) {
        return new Promise((resolve, reject) => {
            this.axiosPostArray({
                url: config.API.SCHOOL_FORM_INFO_SAVE,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }
    // 复制
    schoolFormInfoCopy (data) {
      return new Promise((resolve, reject) => {
          this.axiosPostArray({
              url: config.API.SCHOOL_FORM_INFO_COPY,
              data: data,
              header: 'application/json',
              success (data) {
                  resolve({
                      data,
                      status: 0
                  });
              },
              error (error) {
                  resolve({
                      error,
                      status: - 1
                  })
              }
          });
      });
  }
    schoolFormMobileGroup (data) {
        return new Promise((resolve, reject) => {
            this.axiosPostArray({
                url: config.API.SCHOOL_FORM_MOBILE_GROUP,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    }
    // 获取详情
    getDetailInfo (data) {
        return new Promise((resolve, reject) => {
            this.axiosPostArray({
                url: config.API.GET_FORM_INFO_DATA + data.id,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }
    // oa审批列表分页查询
    getSchoolProcessApplyList (data) {
        return new Promise((resolve, reject) => {
            this.axiosPostArray({
                url: config.API.GET_SCHOOL_PROCESS_APPLY_LIST,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    }

    setSchoolFormInfoSort (data) {
      return new Promise((resolve, reject) => {
          this.axiosPostArray({
              url: config.API.SET_SCHOOL_FORM_INFO_SORT,
              data: data,
              header: 'application/json',
              success (data) {
                  resolve({
                      data,
                      status: 0
                  });
              },
              error (error) {
                  resolve({
                      error,
                      status: - 1
                  })
              }
          });
      });
  }

    getFormInfoData(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_FORM_INFO_DATA + `${data}`,
                // data: data,
                // header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };
    saveSchoolProcessApply(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.SAVE_SCHOOL_PROCESS_APPLY,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };
    getProcess(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_PROCESS,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };
    getProcessApplyInfo(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.SCHOOL_PROCESS_APPLY_INFO,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };
    schoolInterconnection(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.SCHOOL_INTERCONNECTION,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };
    schoolProcessApproval(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.SCHOOL_PROCESS_APPROVAL,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };
    schoolProcessRevoke(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.SCHOOL_PROCESS_APPLY_REVOKE,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };
    schoolProcessRevokeAll(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.SCHOOL_PROCESS_APPLY_REVOKEALL,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };
    schoolProcessEdit(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.SCHOOL_PROCESS_APPLY_EDIT,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };

    getContentDetails(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_CONTENT_DETAIL,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };

    getContentDetail(data) {
        return new Promise((resolve, reject) => {
            this.axiosPost({
                url: config.API.GET_CONTENT_DETAIL,
                data: data,
                header: 'application/json',
                success(data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error(error) {
                    resolve({
                        error,
                        status: -1
                    })
                }
            });
        });
    };

    setUpdateStatus(data) {
        return new Promise((resolve, reject) => {
            this.axiosPostArray({
                url: config.API.SET_UPDATE_STATUS,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    };

    getUserByOrgan(data) {
        return new Promise((resolve, reject) => {
            this.axiosPostArray({
                url: config.API.GET_PERSONNEL_INFO,
                data: data,
                header: 'application/json',
                success (data) {
                    resolve({
                        data,
                        status: 0
                    });
                },
                error (error) {
                    resolve({
                        error,
                        status: - 1
                    })
                }
            });
        });
    };

    // 获取可选换班时间（排班日期）
    getShiftChangeDate(data) {
      return new Promise((resolve, reject) => {
        this.axiosPost({
          url: config.API.GET_SHIFT_CHANGE_DATE,
          data: data,
          header: 'application/json',
          success(data) {
            resolve({
              data,
              status: 0
            });
          },
          error(error) {
            resolve({
              error,
              status: -1
            })
          }
        });
      });
    };

    // 获取宿舍信息
    getDormInfo(data) {
      return new Promise((resolve, reject) => {
        this.axiosPost({
          url: config.API.GET_DORM_INFO,
          data: data,
          header: 'application/json',
          success(data) {
            resolve({
              data,
              status: 0
            });
          },
          error(error) {
            resolve({
              error,
              status: -1
            })
          }
        });
      });
    };

    // 按半天请假选择日期后获取时分秒
    getHalfDayRealTime(data) {
      return new Promise((resolve, reject) => {
        this.axiosPost({
          url: config.API.GET_HALF_DAY_REAL_TIME,
          data: data,
          header: 'application/json',
          success(data) {
            resolve({
              data,
              status: 0
            });
          },
          error(error) {
            resolve({
              error,
              status: -1
            })
          }
        });
      });
    };

    // 校验是否允许请半天假
    checkAllowHalfDayLeave(data) {
      return new Promise((resolve, reject) => {
        this.axiosPost({
          url: config.API.CHECK_ALLOW_HALF_DAY_LEAVE,
          data: data,
          header: 'application/json',
          success(data) {
            resolve({
              data,
              status: 0
            });
          },
          error(error) {
            resolve({
              error,
              status: -1
            })
          }
        });
      });
    };

    // 获取请假时间是否重叠
    getProcessApplyCheckApplyDate(data) {
      return new Promise((resolve, reject) => {
        this.axiosPost({
          url: config.API.SCHOOL_PROCESS_APPLY_INFO_CHECK_DATE,
          data: data,
          header: 'application/json',
          success(data) {
            resolve({
              data,
              status: 0
            });
          },
          error(error) {
            resolve({
              error,
              status: -1
            })
          }
        });
      });
    };

    // 获取请假时长明细
    getLeaveDurationDetails(data) {
      return new Promise((resolve, reject) => {
        this.axiosPost({
          url: config.API.GET_LEAVE_DURATION_DETAILS,
          data: data,
          header: 'application/json',
          success(data) {
            resolve({
              data,
              status: 0
            });
          },
          error(error) {
            resolve({
              error,
              status: -1
            })
          }
        });
      });
    };

    // 特殊请假-获取单个请假组的时长
    getProcessApplySpecialVacationDuration(data) {
      return new Promise((resolve, reject) => {
        this.axiosPost({
          url: config.API.SCHOOL_PROCESS_APPLY_SPECIAL_VACATION_DURATION,
          data: data,
          header: 'application/json',
          success(data) {
            resolve({
              data,
              status: 0
            });
          },
          error(error) {
            resolve({
              error,
              status: -1
            })
          }
        });
      });
    };

  // 查询教师剩余多少调休
  getStaffCompensatoryLeaveDay(data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.GET_STAFF_COMPENSATORY_LEAVE_DAY,
        data: data,
        header: 'application/json',
        success(data) {
          resolve({
            data,
            status: 0
          });
        },
        error(error) {
          resolve({
            error,
            status: -1
          })
        }
      });
    });
  };
  // 特殊请假校验时间段是否存在请假单
  getProcessApplyCheckDateBySpecial(data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.SCHOOL_PROCESS_APPLY_CHECK_DATE_BY_SPECIAL,
        data: data,
        header: 'application/json',
        success(data) {
          resolve({
            data,
            status: 0
          });
        },
        error(error) {
          resolve({
            error,
            status: -1
          })
        }
      });
    });
  };
  // 特殊请假-获取子假条列表
  getSpecialChildApplyInfo(data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.GET_CHILD_APPLY_INFO,
        data: data,
        header: 'application/json',
        success(data) {
          resolve({
            data,
            status: 0
          });
        },
        error(error) {
          resolve({
            error,
            status: -1
          })
        }
      });
    });
  };
  // 判断当前教师是否为班主任
  getCheckIsMasterTeacher(data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.GET_CHECK_IS_MASTER_TEACHER,
        data: data,
        header: 'application/json',
        success(data) {
          resolve({
            data,
            status: 0
          });
        },
        error(error) {
          resolve({
            error,
            status: -1
          })
        }
      });
    });
  };
  // 获取员工剩余假期及详情
  getVacationLeaveBalance(data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.SCHOOL_VACATION_GET_LEAVE_BALANCE,
        data: data,
        header: 'application/json',
        success(data) {
          resolve({
            data,
            status: 0
          });
        },
        error(error) {
          resolve({
            error,
            status: -1
          })
        }
      });
    });
  };
  // 判断是否有审批中请假单（设置了假期余额balanceSwitch为1请求）
  getProcessHasOngiongApplicaion(data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.SCHOOL_PROCESS_APPLY_HAS_ONGOING_APPLICATION,
        data: data,
        header: 'application/json',
        success(data) {
          resolve({
            data,
            status: 0
          });
        },
        error(error) {
          resolve({
            error,
            status: -1
          })
        }
      });
    });
  };
  // 判断当前教师是否为班主任
  getCheckIsMasterTeacher(data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.GET_CHECK_IS_MASTER_TEACHER,
        data: data,
        header: 'application/json',
        success(data) {
          resolve({
            data,
            status: 0
          });
        },
        error(error) {
          resolve({
            error,
            status: -1
          })
        }
      });
    });
  };

  // 保存签名
  saveSign(data) {
    return new Promise((resolve, reject) => {
      this.axiosFile({
        url: config.API.SAVE_SIGN,
        data: data,
        header: 'application/json',
        success(data) {
          resolve({
            data,
            status: 0
          });
        },
        error(error) {
          resolve({
            error,
            status: -1
          })
        }
      });
    });
  };

  // 获取历史签名
  getHistorySign(data) {
    return new Promise((resolve, reject) => {
      this.axiosPost({
        url: config.API.GET_HISTORY_SIGN,
        data: data,
        header: 'application/json',
        success(data) {
          resolve({
            data,
            status: 0
          });
        },
        error(error) {
          resolve({
            error,
            status: -1
          })
        }
      });
    });
  };
}

export { CampusOAModel };
